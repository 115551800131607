import { auth } from "@clerk/nextjs";
import { PHASE_PRODUCTION_BUILD } from "next/constants";
import { HappydogsContext } from "./happydogsContext";
import { ProblemDetails } from "./happydogsSchemas";

export type ErrorWrapper<TError> = TError | { status: "unknown"; payload: string };

export type HappydogsFetcherOptions<TBody, THeaders, TQueryParams, TPathParams> = {
  url: string;
  method: string;
  body?: TBody;
  headers?: THeaders;
  queryParams?: TQueryParams;
  pathParams?: TPathParams;
  signal?: AbortSignal;
} & HappydogsContext["fetcherOptions"];

export async function happydogsFetch<
  TData,
  TError,
  TBody extends {} | FormData | undefined | null,
  THeaders extends {},
  TQueryParams extends {},
  TPathParams extends {},
>({
  url,
  method,
  body,
  headers,
  pathParams,
  queryParams,
  signal,
}: HappydogsFetcherOptions<TBody, THeaders, TQueryParams, TPathParams>): Promise<TData> {
  const requestHeaders: HeadersInit = {
    "Content-Type": "application/json",
    ...headers,
  };

  /**
   * As the fetch API is being used, when multipart/form-data is specified
   * the Content-Type header must be deleted so that the browser can set
   * the correct boundary.
   * https://developer.mozilla.org/en-US/docs/Web/API/FormData/Using_FormData_Objects#sending_files_using_a_formdata_object
   */
  if (requestHeaders["Content-Type"].toLowerCase().includes("multipart/form-data")) {
    delete requestHeaders["Content-Type"];
  }

  if (!url.endsWith("/public") && typeof window === "undefined" && process.env.NEXT_PHASE !== PHASE_PRODUCTION_BUILD) {
    try {
      const { userId, getToken } = auth();
      if (userId) {
        requestHeaders["Authorization"] = `Bearer ${await getToken()}`;
      }
    } catch (e) {
      /**
       * This can happen because of a static page being generated at runtime.
       * Regardless, we don't want to throw an error here as it can cause a 500 error on the page.
       * Just try to make the request without the Authorization header.
       */

      console.warn("Failed to set Authorization header for this request");
    }

    const happydogs_seq_nr_v1 = (await import("next/headers")).cookies().get("happydogs_seq_nr_v1");

    if (happydogs_seq_nr_v1) {
      requestHeaders["Cookie"] = `happydogs_seq_nr_v1=${happydogs_seq_nr_v1.value}`;
    }
  }

  const baseUrl = typeof window === "undefined" ? process.env.API_URL : window.location.origin;
  const finalUrl = `${baseUrl}${resolveUrl(url, queryParams, pathParams)}`;
  const tags = pathParams ? Object.entries(pathParams).map((entry) => entry.join("/")) : [];
  const response = await fetch(finalUrl, {
    signal,
    method,
    body: body ? (body instanceof FormData ? body : JSON.stringify(body)) : undefined,
    headers: requestHeaders,
    credentials: "include",
    next: {
      tags,
      cache: tags.length > 0 ? "force-cache" : "no-store",
    },
  } as RequestInit);

  if (response.headers.get("content-type")?.includes("json")) {
    const data = await response.json();

    if (response.status >= 400) {
      throw data as ProblemDetails;
    }

    return data as TData;
  }

  if (!response.ok) {
    throw {
      title: `Network error ${response.status}`,
      detail: response.statusText,
      status: response.status,
      type: "Unknown",
    } as ProblemDetails;
  }

  return (await response.text()) as unknown as TData;
}

const resolveUrl = (url: string, queryParams: Record<string, string> = {}, pathParams: Record<string, string> = {}) => {
  let query = new URLSearchParams(queryParams).toString();
  if (query) query = `?${query}`;
  return url.replace(/\{\w*}/g, (key) => pathParams[key.slice(1, -1)]) + query;
};
